import { Component, OnDestroy, OnInit, Self } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toast-container',
  standalone: true,
  imports: [CommonModule, NgbModule],
  providers: [],
  templateUrl: './toast-container.component.html',
  styleUrl: './toast-container.component.scss'
})
export class ToastContainerComponent implements OnInit {
  toasts: any[] = [];
  toastSubscription: Subscription;

  constructor(private toastService: ToastService) { }

  ngOnInit() {
    this.toastService.toastChanged.subscribe({
      next: (toasts) => {
        this.toasts = toasts;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  remove(toast) {
    this.toastService.remove(toast);
  }
}
