<div class="container">
  <div class="header-div">
    <h3>Customer List</h3>
  </div>
  <div class="customer-list">
    <ng-container>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="slNo">
          <th mat-header-cell *matHeaderCellDef> SL No. </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{ i + 1 }} </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef> Customer Name </th>
          <td mat-cell *matCellDef="let element"> {{element.customerName ? element.customerName : '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="accID">
          <th mat-header-cell *matHeaderCellDef> Account ID </th>
          <td mat-cell *matCellDef="let element"> {{element.accId ? element.accId : '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="sanctionLimit">
          <th mat-header-cell *matHeaderCellDef> Sanction Limit (Cr.) </th>
          <td mat-cell *matCellDef="let element"> {{element.sanctionLimit ? element.sanctionLimit : '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="utilizedLimit">
          <th mat-header-cell *matHeaderCellDef> Utilized Limit (Cr.)</th>
          <td mat-cell *matCellDef="let element"> {{element.utilizeLimit ? element.utilizeLimit : '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="interestRate">
          <th mat-header-cell *matHeaderCellDef> Interest Rate</th>
          <td mat-cell *matCellDef="let element"> {{element.interestRate ? element.interestRate : '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element" class="statusText"
            [ngClass]="element.status == 'pending' ? 'pendingStatus' : 'approvedStatus'"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button color="primary" class="actionBtn" (click)="actionOnBuyer(element)">
              {{element.status == 'PENDING' ? 'Approve':'Stop Supply'}}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </ng-container>

  </div>
</div>