<div class="wrapper">

  <div class="form">
    <div class="card">
      <h5 id="heading" style="color: black;">Sign Up for Dealer Finance</h5>
      <form [formGroup]="registerForm">
        <div class="input-container">
          <label for="companyName">Company Name</label>
          <input type="text" matInput id="companyName" formControlName="companyName"
            placeholder="Enter the Company Name">
        </div>

        <div class="input-container">
          <label for="gstIn">GSTIN</label>
          <input type="text" matInput id="gstIn" formControlName="gstIn" placeholder="Enter the GST Number">
          <label style="color: red;"
            *ngIf="registerForm.get('gstIn').hasError('invalidGSTIN') && registerForm.get('gstIn').touched">
            Invalid GSTIN
          </label>
        </div>

        <div class="input-container">
          <label for="pan">PAN</label>
          <input type="text" matInput id="pan" formControlName="pan" placeholder="Enter PAN Number">
        </div>

        <div class="input-container">
          <label for="contactNo">Contact Number</label>
          <input type="number" matInput id="contactNo" formControlName="contactNo"
            placeholder="Enter your Contact Number" maxlength="10">
        </div>

        <div class="input-container">
          <label for="email">Email</label>
          <input type="text" matInput id="email" formControlName="email" placeholder="Enter your Email">
        </div>

        <div class="input-container">
          <label for="password">Password</label>
          <input type="password" matInput id="password" formControlName="password" placeholder="Enter your password">
        </div>

        <div class="input-container">
          <label for="confirmPassword">Confirm Password</label>
          <input type="password" matInput id="confirmPassword" formControlName="confirmPassword"
            placeholder="Enter your password">
          <label style="color: red;"
            [hidden]="registerForm.get('confirmPassword').value === registerForm.get('password').value">Passwords do not
            match</label>
        </div>

        <div class="input-container">
          <mat-checkbox (change)="ischeckedTerms($event.checked)">
            <span id="terms">I have read and accept the terms and conditions</span>
          </mat-checkbox>
        </div>

        <button type="button" (click)="onSubmit()" mat-button [disabled]="isChecked">Sign Up</button>
      </form>
    </div>

  </div>
  <div class="img-class">
    <img src="https://credlix-public.s3.ap-south-1.amazonaws.com/Credlix-Image/Group+82197.svg">
  </div>
</div>