import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { ActivatedRouteSnapshot, provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoadingService } from './services/loader.service';
import { ToastService } from './services/toast.service';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
  provideClientHydration(),
  importProvidersFrom(BrowserAnimationsModule), {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
    ToastService, LoadingService,
  importProvidersFrom(HttpClientModule, ActivatedRouteSnapshot),]
};
