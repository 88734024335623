export const LoanStatus ={
    APPLY : "Apply",
    NOT_YET_APPLIED : "Not yet Applied",
    PENDING_SANCTION : "Pending Sanction",
    APPROVE_SANCTION : "Approve Sanction",
    APPROVED_SANCTION : "Approved Sanction",
    PENDING_DISBURSEMENT : "Pending Disbursement",
    APPROVED_DISBURSEMENT :"Approved Disbursement",
    INITIATE_DISBURSEMENT : "Initiate Disbursement",
    APPROVED : "Approved",
    APPROVE : "Approve",
    PENDING : "Pending",
    SANCTIONED : "Sanctioned",
    STOP_SUPPLY : "Stop Supply",
    RESUME_SUPPLY : "Resume Supply",
    INACTIVE : "Inactive"
}