export const urls = {
  LOGIN: 'user/generateToken',
  SIGNUP: 'user/sign-up',

  // financier
  ADD_FINANCIER: 'financier/add',
  GET_FINCIER_DOCS: 'kyc/get-doc',
  GET_FINANCIER: 'financier/get',

  //Dashbaord
  ACCOUNT_DETILS: 'user/detail',
  DASHBOARD_DATA: 'financier/get',
  GET_SUPPLIER_FINANCIER: 'financier/get/supplier',
  GET_BUYER_ACCOUNT_DETAILS: 'buyer/get-bank',
  setFinanceReq: 'buyer/apply',
  initiateDisbursement: 'buyer/initiate',
  GET_INTEREST_RATE: 'buyer/interest',

  // :'user/add-bank',
  // :'user/get-bank,
  //Add KYC Urls
  ADD_KYC: 'kyc/add',
  GET_KYC_DATA: 'kyc/get-kyc',
  SAVE_KYC_DETAILS: 'kyc/add',
  UPLOAD_DOCUMENT: 'kyc/add-doc',
  LOAN_KYC_DOC: 'kyc/get-docs',
  ADD_CUSTOMER: 'supplier/onboard-buyer',

  //apply For Loan for buyer
  APPLY_FOR_LOAN: 'buyer/apply',
  INITIATE_DISBURENMENT: 'buyer/initiate',

  //Buyer Details
  GET_BUYER_DETAILS: 'financier/get/buyer',
  GET_CUSTOMER_DETAILS: 'buyer/get',
  ADD_BANKS_DETAILS: 'buyer/add-bank',
  GET_BUYER_LIST: 'supplier/get-buyer',
  APPROVE_SANCTION: 'buyer/approve',

  //reports
  GET_REPORT_LIST: 'report/buyer-table',
  DOWNLOAD_REPORT: 'report/buyer-mis',
};
