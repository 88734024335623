import { Routes } from '@angular/router';
import { AddFinancierComponent } from './add-financier/add-financier.component';
import { AuthGuard } from './auth/auth.guard';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { KycDocComponent } from './customer/customer-detail/kyc-doc/kyc-doc.component';
import { CustomerComponent } from './customer/customer.component';
import { BuyerDashboardComponent } from './dashboard/buyer-dashboard/buyer-dashboard.component';
import { BuyersListComponent } from './dashboard/supplier-dashboard/buyers-list/buyers-list.component';
import { SupplierDashboardComponent } from './dashboard/supplier-dashboard/supplier-dashboard.component';
import { LoginComponent } from './login/login.component';
import { KycDocumentComponent } from './my-account/kyc-document/kyc-document.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { SignupComponent } from './signup/signup.component';
import { SupplierReportComponent } from './reports/supplier-report/supplier-report.component';
import { BuyerReportComponent } from './reports/buyer-report/buyer-report.component';

export const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'myaccount', component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: 'kycDoc', component: KycDocumentComponent, canActivate: [AuthGuard] },
  { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard] },
  { path: 'addFinancer', component: AddFinancierComponent, canActivate: [AuthGuard] },
  { path: 'supplier/dashboard', component: SupplierDashboardComponent, canActivate: [AuthGuard] },
  { path: 'buyer/dashboard', component: BuyerDashboardComponent, canActivate: [AuthGuard] },
  {
    path: 'supplier/dashboard',
    component: SupplierDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier/dashboard/buyer-listing',
    component: BuyersListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-list/:id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'kyc-doc/:id',
    component: KycDocComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier/report',
    component: SupplierReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'buyer/report',
    component: BuyerReportComponent,
    canActivate: [AuthGuard]
  }
];
