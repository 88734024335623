<div class="header">
  <div class="flex-row-header">
    <h3>Bank MIS Report</h3>
    <button class="downoadBtn" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>cloud_download</mat-icon>
      Download </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="downloadReport('xlsx')">
        <mat-icon>table_view</mat-icon>
        <span>Excel file</span>
      </button>
      <button mat-menu-item (click)="downloadReport('csv')">
        <mat-icon>text_snippet</mat-icon>
        <span>CSV file</span>
      </button>
    </mat-menu>
  </div>

  <div class="misreportTbl custom-table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="slNo">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef> Financier Name </th>
        <td mat-cell *matCellDef="let element"> {{element.financier ? element.financier : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="bankName">
        <th mat-header-cell *matHeaderCellDef> Sanction Limit </th>
        <td mat-cell *matCellDef="let element"> {{element.requestedLimit ? element.requestedLimit : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="sanctionLimit">
        <th mat-header-cell *matHeaderCellDef> Interest Rate</th>
        <td mat-cell *matCellDef="let element"> {{element.approvedRate ? element.approvedRate : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="LimitUtilized">
        <th mat-header-cell *matHeaderCellDef> Limit Utilized </th>
        <td mat-cell *matCellDef="let element"> {{element.utilizedLimit ? element.utilizedLimit : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="limitExpirydate">
        <th mat-header-cell *matHeaderCellDef> Limit Expiry Date</th>
        <td mat-cell *matCellDef="let element"> {{addDaysAndFormat(element.createdOn, 364)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
</div>