import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { ToastService } from './../services/toast.service';
import { LoadingService } from '../services/loader.service';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [HttpClient, AuthService, DataService, LoadingService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
  ]
})
export class LoginComponent {
  LoginForm!: FormGroup;
  userInfo: { email: any; password: any; } | undefined;
  accType: string = 'Supplier';
  idtoken: any;
  BaseUrl!: string;
  userRole = signal(0);

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private toasterService: ToastService,
    private loadingService: LoadingService
  ) {
    this.BaseUrl = AuthService.baseUrl || undefined;
  }

  ngOnInit() {
    this.handleIsuserLoggedIn();
    this.LoginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  handleIsuserLoggedIn() {
    if (this.authService.isTokenExpired()) {
      this.authService.deleteSessionValue('TOKEN');
    }
    else {
      this.router.navigate(['/myaccount'])
    }
  }
  onSubmit() {

    this.userInfo = {
      email: this.LoginForm.value.email.toLowerCase().trim(),
      password: this.LoginForm.value.password,
    };
    this.authService.signIn(this.userInfo).subscribe(
      (data: any) => {
        this.setUserData(data);
        this.toasterService.show('Success', 'You Are Logged In');

      },
      (error: any) => {
        this.toasterService.show('Error', 'Email or Password is Wrong');

        console.error(error);
      }
    );
  }

  setUserData(data: any) {
    if (data['success']) {
      this.idtoken = data['token'];
      const decode = jwtDecode(this.idtoken);
      if (decode && decode['role'])
        this.userRole = signal(decode['role']);
      this.authService.createSession(this.idtoken, this.userInfo);
      let tmpData = data['result'];
      // this.routeTo(tmpData);
      this.router.navigate(['/myaccount']);
    } else {
      this.idtoken = data['token'];
      this.authService.createSession(this.idtoken, this.userInfo.email);
    }
  }

  // verifySession() {
  //   let sessionData: any = this.authService.isTokenExpired();
  //   if (sessionData) {
  //     this.router.navigate(['/']);
  //   } else {
  //     this.router.navigate(['/myaccount']);
  //   }
  // }

  loginWithMicrosoft() {
    window.open(this.BaseUrl + 'users/v2/social/login' + '?type=MICROSOFT');
  }

  gotoSignup() {
    this.router.navigate(['/auth/signup']);
  }

  resetPassword() {
    this.router.navigate(['/auth/forgot-password']);
  }
  public getCookie(name: string) {
    return localStorage.getItem(name);
  }
}
