import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportService } from '../report.service';
import { ToastService } from '../../services/toast.service';
import { LoadingService } from '../../services/loader.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-supplier-report',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatMenuModule,
    FormsModule
  ],
  providers: [ReportService, DatePipe],
  templateUrl: './supplier-report.component.html',
  styleUrl: './supplier-report.component.scss'
})
export class SupplierReportComponent implements OnInit {
  dataSource: any;
  displayedColumns: string[] = ['slNo', 'customerName', 'bankName', 'sanctionLimit', 'LimitUtilized', 'limitExpirydate'];
  documentsList = ['MIS Report'];
  selectedReport: any;
  constructor(
    private reportService: ReportService,
    private toasterservice: ToastService,
    private loaderService: LoadingService,
    private datePipe: DatePipe,
    private loadingService: LoadingService
  ) {

  }
  ngOnInit() {
    this.selectedReport = this.documentsList[0]
    // this.getMisReports();
  }
  getMisReports() {
    const Id = ''
    this.reportService.getMisReportsData().subscribe((responce) => {
      if (responce['status'] === 200) {
        this.dataSource = responce['result'];
      }
      else {
        this.toasterservice.show('Error', 'Mis Report loading getting failed');
      }
      ;
    });
  }

  downloadReport(extentionName: string) {
    this.loadingService.setLoadingState(true);
    const obj = {
      reportName: this.selectedReport,
      fileType: extentionName,
    }
    this.reportService.downloadReport(obj).subscribe((responce) => {
      this.triggerDownload(responce, 'filename.xlsx');
      this.toasterservice.show('Success', 'Downloaded');
      this.loadingService.setLoadingState(false);
    })
  }

  private triggerDownload(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  addDaysAndFormat(dateString: string, daysToAdd: number): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return this.datePipe.transform(date, 'fullDate');
  }
  reportNameSelect(event) {
    this.selectedReport = event.option.value;
    console.log(this.selectedReport);
  }
}
