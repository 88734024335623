import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LoanStatus } from '../model/loan-status';
import { AddCusomerDialogComponent } from './add-cusomer-dialog/add-cusomer-dialog.component';
import { CustomerService } from './customer.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../services/loader.service';
import { LenderListDialogComponent } from './lender-list-dialog/lender-list-dialog.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
  providers: [CustomerService, AuthService, ToastrService],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss'
})
export class CustomerComponent implements OnInit {
  loanstatus: any = LoanStatus
  customerAppliedLoans = [];
  userId: string;

  constructor(
    public dialog: MatDialog,
    private customersService: CustomerService,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService
    // private toasterService: ToastrService
  ) {

  }

  ngOnInit() {
    if (this.isBuyerUser()) {
      this.getBuyerCustomersDetails();
    }
    else {
      this.getSupplierCustomerDetails();
    }
  }

  openCustomerDialog() {
    const dialogRef = this.dialog.open(AddCusomerDialogComponent, {
      width: "560px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.fetchBusinessAcounts();
    });
  }

  isBuyerUser() {
    return this.authService.isbuyerUser();
  }
  isSupplieruser() {
    return this.authService.isSupplierUser();
  }

  getBuyerCustomersDetails() {

    this.customersService.getBuyerCustomersDetails().subscribe((responce) => {
      if (responce['status'] === 200) {
        this.customerAppliedLoans = responce['result'];

      }
      else {
        // this.toasterService.show('Error', responce['message'])

      }
    });
  }
  getSupplierCustomerDetails() {

    this.customersService.getSupplierCustomersDetails().pipe(
      catchError(error => {
        // Log the error or do some error handling
        console.error('API call failed: ', error);

        return of(null);
      })
    ).subscribe(response => {
      if (response && response['status'] === 200) {
        this.customerAppliedLoans = response['result'];
      }

    });
  }
  openAccountDetails(userId: string) {
    this.router.navigate([`/customer-list/${userId}`]);
  }

  openLenderList(buyerId: string) {
    console.log(buyerId);
    const dialogRef = this.dialog.open(LenderListDialogComponent, {
      width: "auto",
      height: "auto",
      data: { buyerId: buyerId },
      position: {
        top: '170px',
      }
    });
    // dialogRef.afterClosed().subscribe((result) => {
    // });
  }
}
