import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardService } from '../dashboard.service';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterOutlet } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { FinancierDailogComponent } from './financier-dailog/financier-dailog.component';

@Component({
  selector: 'app-supplier-dashboard',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterOutlet],
  providers: [DashboardService],
  templateUrl: './supplier-dashboard.component.html',
  styleUrl: './supplier-dashboard.component.scss'
})
export class SupplierDashboardComponent {
  financierData: any = [];

  constructor(
    private dashboardService: DashboardService, private loadingService: LoadingService,
    private router: Router, private dialog: MatDialog) {
  }
  ngOnInit() {
    this.fetchFinancierLenders();
  }

  fetchFinancierLenders() {
    //
    this.loadingService.setLoadingState(true);
    this.dashboardService.getSupplierLender().subscribe(
      (res) => {
        if (res['status'] === 200) {
          this.financierData = res.result;

          this.loadingService.setLoadingState(false);
        }
      },
      (error) => {
        this.financierData = [];
        this.loadingService.setLoadingState(false);
      },

    );
  }

  showBuyersOfCurrentLender(currentFinacier) {
    let lenderId = currentFinacier.lenderId;
    this.router.navigate(['/supplier/dashboard/buyer-listing'], {
      queryParams: {
        lenderId: lenderId,
      }
    });
  }

  openGetKycDetail(lenderId) {
    const dialogRef = this.dialog.open(FinancierDailogComponent, {
      width: window.innerWidth - 100 + 'px',
      height: '92%',
      maxWidth: window.innerWidth - 100 + 'px',
      position: {
        top: '70px',
      },
      data: {
        lenderID: lenderId
      }
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }
}
