import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validator,
  Validators,
} from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule
  ],
  providers: [DataService, AuthService],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
})
export class SignupComponent implements OnInit {
  registerForm!: FormGroup;
  idtoken: any;
  userDetails!: string;
  isChecked: boolean = true;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    public router: Router,
    private authService: AuthService

  ) {
    let isExpired = this.authService.isTokenExpired();
    if (!isExpired) {
      this.router.navigate(['/myaccount'])
    }
    else {
      this.authService.deleteSessionValue('TOKEN');
    }
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      gstIn: ['', [Validators.required, this.gstinValidator]],
      companyName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      user_type: ['', Validators.required],
      contactNo: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      pan: ['']
    });


    this.registerForm.get('gstIn').valueChanges.subscribe((gstinValue) => {
      this.updatePan(gstinValue);
    });

    this.registerForm.get('confirmPassword').valueChanges.subscribe(() => {
      this.registerForm.get('confirmPassword').updateValueAndValidity();
    });
  }


  onSubmit() {
    this.registerForm.controls['user_type'].setValue('SUPPLIER');
    let obj: signupModels = this.registerForm.value;
    if (this.registerForm.valid) {
      this.authService.signUp(obj).subscribe((data: any) => {
        if (data.status === 200 && data.success === true) {
          this.router.navigate(['/']);
        } else {
          alert('Signup failed');
        }
      });
    }
  }

  gstinValidator(control: AbstractControl): { [key: string]: any } | null {
    // const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    const gstinRegex = /^[0-9A-Z]{15}(?!\s)$/i;

    const valid = gstinRegex.test(control.value);

    return valid ? null : { 'invalidGSTIN': true };
  }

  ischeckedTerms(value: any) {
    this.isChecked = !value;
  }



  updatePan(gstin: string) {
    if (gstin.length >= 2) {
      const panValue = gstin.substring(2, 12);
      this.registerForm.get('pan').setValue(panValue);
    }
    else {
      this.registerForm.get('pan').setValue('');
    }
  }




}
export interface signupModels {
  gstIn: number;
  companyName: string;
  email: string;
  password: string;
  user_type: string;
  contactNo: string;
  pan: string;
}

