import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CustomerService } from '../customer.service';
import { DataService } from '../../data.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from './../../services/toast.service';
import { LoadingService } from '../../services/loader.service';

@Component({
  selector: 'app-add-cusomer-dialog',
  standalone: true,
  imports: [CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [CustomerService, DataService, LoadingService],
  templateUrl: './add-cusomer-dialog.component.html',
  styleUrl: './add-cusomer-dialog.component.scss'
})
export class AddCusomerDialogComponent implements OnInit {
  customerForm: FormGroup;
  lenderDetails: any;
  data: any;
  hide: any = true;
  filteredSuppliers: any;

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<AddCusomerDialogComponent>,
    private toasterService: ToastService,
    private loadingService: LoadingService) {
  }
  ngOnInit() {
    this.customerForm = this.fb.group({
      companyName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      gstIn: [null, Validators.required],
      emailId: [null, Validators.required],
    });
  }

  onSubmit() {
    this.loadingService.setLoadingState(true);
    this.customerService.addCustomer(this.customerForm.value).subscribe({
      next: (res) => {
        if (res['status'] == 200) {
          this.dialogRef.close();
          this.toasterService.show('Success', 'Buyer has been Onborded Succssfully');
        }
        else {
          this.toasterService.show('Error', res?.['message']);
        }
      },
      error: (error) => {
        this.toasterService.show('Error', 'Something went Wrong');

      },
    });
    this.loadingService.setLoadingState(false);
  }
}
