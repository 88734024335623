import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { DataService } from './data.service';
import { ToastService } from './services/toast.service';
import { ToastContainerComponent } from "./shared/toast-container/toast-container.component";
import { LoaderComponent } from './shared/loader/loader.component';
import { LoadingService } from './services/loader.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  providers:
    [
      DataService,
      AuthService,
      ToastService,
      LoadingService,
    ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    RouterOutlet,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatMenuModule,
    RouterModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    ToastContainerComponent,
    LoaderComponent
  ]
})
export class AppComponent implements OnInit {
  title = 'Credlix Dealer Finance';
  loader = false;
  constructor(
    private router: Router,
    public dataService: DataService,
    public authService: AuthService,
    private toastService: ToastService,
    public loadingService: LoadingService
  ) {

  }
  ngOnInit(): void {
    this.loadingService.loadingSubject.subscribe(data => {
      this.loader = data;
    });
  }

  logout() {
    this.dataService.deleteCookie('TOKEN');
    this.router.navigate(['/login']);
    this.toastService.show('Success', 'Succesfully Logged Out!');
  }
  isBuyerUser() {
    return this.authService.isbuyerUser();
  }
  isSupplieruser() {
    return this.authService.isSupplierUser();
  }
}
