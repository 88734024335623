import { Injectable, Inject, PLATFORM_ID, signal } from '@angular/core';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';
import { urls } from '../urls/urls';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class AddFinancierService {
  constructor(private dataService: DataService) { }
  addfinancer(obj: any) {
    const url = environment.BASEURL + urls.ADD_FINANCIER;
    return this.dataService.addFinancer(obj);
  }
}
