<ng-container>
  <div class="header">
    <h3 class="headerText">Interest Rate Record</h3>
  </div>
  <div class="content">
    <p class="rate">
      <span class="label">Requested Interest Rate:</span>
      <span class="value" id="requestedRate">
        {{interstRate.requestedInterestrate ? interstRate.requestedInterestrate : '--'}}
      </span>
    </p>
    <p class="rate">
      <span class="label">Approved Interest Rate:</span>
      <span class="value" id="approvedRate">
        {{interstRate.approvedInterestrate ? interstRate.approvedInterestrate : '--'}}
      </span>
    </p>
  </div>
</ng-container>