import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportService } from '../report.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { ToastService } from '../../services/toast.service';
import { LoadingService } from '../../services/loader.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-buyer-report',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatMenuModule
  ],
  providers: [ReportService, DatePipe],
  templateUrl: './buyer-report.component.html',
  styleUrl: './buyer-report.component.scss'
})
export class BuyerReportComponent implements OnInit {
  displayedColumns: string[] = ['slNo', 'customerName', 'bankName', 'sanctionLimit', 'LimitUtilized', 'limitExpirydate'];
  dataSource: any[];
  constructor(
    private reportService: ReportService,
    private datePipe: DatePipe,
    private toasterservice: ToastService,
    private loaderService: LoadingService) {
    ;

  }
  ngOnInit() {
    this.getMisReports();
  }

  getMisReports() {
    const Id = ''
    this.reportService.getMisReportsData().subscribe((responce) => {
      if (responce['status'] === 200) {
        this.dataSource = responce['result'];
      }
      else {
        this.toasterservice.show('Error', 'Mis Report loading getting failed');
      }
      ;
    });
  }

  downloadReport(extensionName: string) {
    const obj = {
      reportName: 'misReport',
      fileType: extensionName,
    };

    this.reportService.downloadReport(obj).pipe(
      catchError(err => {
        this.toasterservice.show('Error', 'Download action Failed, Please try again');
        return of(err); // Return an observable with the error
      })
    ).subscribe(response => {
      let filename = 'report.' + extensionName;
      this.triggerDownload(response, filename);
      this.toasterservice.show('Success', 'Downloaded');
    });
  }


  private triggerDownload(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  addDaysAndFormat(dateString: string, daysToAdd: number): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return this.datePipe.transform(date, 'fullDate');
  }
}
