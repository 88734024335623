import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { DATE_FORMAT } from '../../../model/dateFormat';
import { DataService } from '../../../data.service';
import { HttpClientModule } from '@angular/common/http';
import { DialogRef } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingService } from '../../../services/loader.service';
@Component({
  selector: 'app-buyer-disbursement-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    MatNativeDateModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [
    MatNativeDateModule,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT
    },
    DashboardService, DataService
  ],
  templateUrl: './buyer-disbursement-dialog.component.html',
  styleUrl: './buyer-disbursement-dialog.component.scss'
})
export class BuyerDisbursementDialogComponent implements OnInit {
  date: any;
  invoiceNumber: string;
  poValue: string;
  amount: string;
  poDate: string;

  constructor(
    private dashboardService: DashboardService,
    private dialogRef: DialogRef, @Inject(MAT_DIALOG_DATA) public data: any,
    private loaderService: LoadingService) { }
  ngOnInit() {

  }
  gotoLenderSite() {
    window.open('https://www.hdfcbank.com/');
  }

  closePopup() {
    this.dialogRef.close();
  }
  onSubmit() {
    let obj = {
      lenderId: this.data.lenderId,
      supplierId: this.data.supplierId,
      invoiceNumber: this.invoiceNumber,
      poValue: this.poValue,
      amount: this.amount,
      poDate: new Date(this.poDate).getTime()
    }
    this.dashboardService.initiateDisbursement(obj).subscribe(res => {
      this.dialogRef.close();
    })
  }
}
