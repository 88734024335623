import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardService } from '../../dashboard.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-interest-record',
  standalone: true,
  imports: [CommonModule],
  providers: [DashboardService],
  templateUrl: './interest-record.component.html',
  styleUrl: './interest-record.component.scss'
})
export class InterestRecordComponent implements OnInit {
  interstRate: any = {};

  constructor(
    private dashboadService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.getInterestRecord();
  }

  getInterestRecord() {
    const obj = {
      lenderId: this.data.lenderId,
      supplierId: this.data.supplierId,
    }
    this.dashboadService.getInterestRecord(obj).subscribe(data => {
      if (data['status'] === 200) {
        this.interstRate = data['result']
      }
    });
  }
}
