<div class="cf-container-supplier">

  <section class="cf-lenders-main">
    <div class="cf-lender-list">
      <h2> Lender List </h2>
      <div class="table-responsive">
        <table>
          <thead class="th-bg">
            <tr>
              <th>SL No.</th>
              <th class="finance">Financing Partner</th>
              <th class="random-row">Sanction Limit (Cr.)</th>
              <th class="random-row">Utilized Limit (Cr.)</th>
              <th class="random-row">Interest Rate</th>
              <th class="random-row">Total Customer using CF</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let financier of financierData;let i = index;">
              <td>{{i+1}}</td>
              <td>
                <a (click)="openGetKycDetail(financier.lenderId)" class="view">{{financier.financierName}}</a>
              </td>
              <td>{{financier.limit ? financier.limit?.toFixed(2) : '-'}} </td>
              <td>{{financier.utilizedLimit ? financier.utilizedLimit?.toFixed(2) : '-'}}</td>
              <td>{{financier.interestRate ? financier.interestRate?.toFixed(2) : '-'}}%</td>
              <td>
                <h4>
                  <!-- {{financier.totalBankCustomerCount ? financier.totalBankCustomerCount : '-'}} -->
                  <a [ngClass]="financier.totalLoanRequestCount == 0 ?'changes':'changes2'">
                    <mat-icon (click)="showBuyersOfCurrentLender(financier)">people</mat-icon>
                  </a>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>