<div class="dialogDiv">

  <div class="header">
    <button class="addnewBtn" mat-button color="primary" (click)="addnewRow()">
      <mat-icon>add_circle</mat-icon>ADD More</button>
    <mat-icon (click)="closePopup()">close</mat-icon>
  </div>

  <div class="inpFields">
    <div *ngFor="let item of bankDetails; let i = index" class="clearfix">

      <div class="f-field">
        <mat-form-field appearance="outline">
          <mat-label>Account Number</mat-label>
          <input matInput [(ngModel)]="item.accNo" />
        </mat-form-field>
      </div>

      <div class="f-field">
        <mat-form-field appearance="outline">
          <mat-label>Bank Name</mat-label>
          <input matInput [(ngModel)]="item.bankName" />
        </mat-form-field>
      </div>

      <div class="f-field">
        <mat-form-field appearance="outline">
          <mat-label>Branch Address</mat-label>
          <input matInput [(ngModel)]="item.branchName" />
        </mat-form-field>
      </div>

      <div class="f-field">
        <mat-form-field appearance="outline">
          <mat-label>IFSC Code</mat-label>
          <input matInput [(ngModel)]="item.ifscCode" />
        </mat-form-field>
      </div>
      @if(bankDetails.length>1){
      <div>
        <mat-icon (click)="deleteRow(i)">delete</mat-icon>
      </div>
      }
      <!-- @if(contactDetails.length > 1){
      <button mat-button color="warn" class="v-btn removeContact" type="button" (click)="removeContactPerson(i)">
        <mat-icon>remove_circle</mat-icon><span> Remove </span>
      </button>
      } -->
    </div>
  </div>
  <button class="addnewBtn submitBtn" mat-button color="primary" (click)="addBanksDetail()">Submit</button>
</div>