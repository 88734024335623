<ng-container class="disbursContainer">
  <div class="content">
    <div class="header">
      <p class="lenderHeader">Initiate Disbursement Request in Lender’s Website </p>
      <mat-icon class="lenderIcon" (click)="gotoLenderSite()">touch_app</mat-icon>
    </div>
    <div class="initiateDiv">
      <p class="center">Or</p>
      <div class="initiateHeader">
        <div class="msg">
          <p>Initiate Disbursement Here</p>
          <mat-icon class="hereIcon"> beenhere</mat-icon>
        </div>
        <span class="warningMsg">
          <p>Before applying for loan kindly provide few details to help us provide you the better credit limit.</p>
        </span>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Invoice Number</mat-label>
        <input matInput type="text" [(ngModel)]="invoiceNumber"/>
      </mat-form-field>
      <!-- <button mat-button class="viewPoBtn" color="primary"> View PO</button> -->
    </div>
    <div class="submitReq">
      <div class="inputField">
        <mat-form-field appearance="outline">
          <mat-label>PO Value</mat-label>
          <input matInput type="text" [(ngModel)]="poValue"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Disbursenment Amount</mat-label>
          <input matInput type="number" [(ngModel)]="amount" />
        </mat-form-field>
        <mat-form-field>
          <input matInput [matDatepicker]="startPicker" placeholder="From Date" [(ngModel)]="poDate">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <!-- <input matInput type="date" class="form-control" placeholder="Date of Incorporation"
          formControlName="dateOfIncorporation">
        <label>Date of
          Incorporation</label> -->
      </div>
      <div class="btns">
        <button mat-button class="cancelDisburs" color="primary" (click)="closePopup()">Cancel</button>
        <button mat-button class="submitDisb" color="primary" (click)="onSubmit()">Submit Disbursenment Request</button>
      </div>
    </div>

  </div>
</ng-container>