import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];
  toastChanged = new BehaviorSubject<any[]>([]);

  constructor() { }

  show(type: string, content: string) {
    const toast = { type, content };
    this.toasts.push(toast);
    this.toastChanged.next(this.toasts)
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
    this.toastChanged.next(this.toasts);
  }
}
