import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, Routes } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
// import { KycDocumentComponent } from './kyc-document/kyc-document.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MyAccountService } from './myAccount.service';
import { KycDocumentComponent } from './kyc-document/kyc-document.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddBankComponent } from './basic-details/add-bank/add-bank.component';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../auth/auth.service';
import { LoadingService } from '../services/loader.service';
declare var $: any;
@Component({
  selector: 'app-my-account',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatTabsModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule
  ],
  providers: [MyAccountService, AuthService],
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss',
})
export class MyAccountComponent implements OnInit {
  @Input() public hideUsersInfo: any;
  isCustomerKycVerified: any;
  dataSource: any = [];
  displayedColumns: any = ['accNumber', 'bankName', 'branchName', 'ifscCode'];
  userDetail: any = {};
  basicDetailShouldDisplay: boolean = true;
  isCustomerKycDone: boolean = false;

  constructor(
    private router: Router,
    private myAccountService: MyAccountService,
    public dialog: MatDialog,
    private authService: AuthService,
    private loadingService: LoadingService
  ) {

  }

  ngOnInit() {
    this.getAccountDetails();
    //Bank details should render only for buyer Users
    if (this.isBuyerUser()) {
      this.getBankDetails();
    }
  }

  kycComp() {
    this.router.navigate(['/kycDoc']);
  }

  getAccountDetails() {
    this.loadingService.setLoadingState(true);
    this.myAccountService.getUserAccountDetails().subscribe({
      next: (data) => {
        this.userDetail = data.result;
        this.loadingService.setLoadingState(false);
      },
      error: (error) => {
        this.userDetail = null;
        this.loadingService.setLoadingState(false);
      },
    });
  }

  getBankDetails() {
    this.loadingService.setLoadingState(true);
    this.myAccountService.getUserBankdetails().subscribe({
      next: (data) => {
        this.dataSource = data['result'];
        this.loadingService.setLoadingState(false);
      },
      error: (error) => {
        this.dataSource = [];
        this.loadingService.setLoadingState(false);
        console.error('Error fetching bank details:', error);
      }
    });
    this.loadingService.setLoadingState(true);
  }

  addBank() {
    const dialogRef = this.dialog.open(AddBankComponent, {
      width: "1050px",
      height: "300px",
      data: {}
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getBankDetails();
    });
  }
  isBuyerUser() {
    return this.authService.isbuyerUser();
  }
  isSupplieruser() {
    return this.authService.isSupplierUser();
  }
}
