import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isTokenExpired() && router.routeConfig.path != 'login' && router.routeConfig.path != '') {
      this.router.navigate(['/login']);
      return false;
    }
    else if (router.routeConfig.path === '' || router.routeConfig.path === 'login') {
      this.router.navigate(['/login']);
      return false;
    }
    else if (router.routeConfig.path === 'signup') {
      this.router.navigate(['/signup']);
      return false;
    }
    else {
      return true;
    }
  }
}
