import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddFinancierService } from '../../../add-financier/add-finacer.service';
import { ToastService } from '../../../services/toast.service';
import { DashboardService } from '../../dashboard.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-financier-dailog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  providers: [DashboardService],
  templateUrl: './financier-dailog.component.html',
  styleUrl: './financier-dailog.component.scss'
})
export class FinancierDailogComponent {
  financierForm!: FormGroup;
  contactPersonForm: FormGroup;
  isAddFinancierRequest: any;
  isFinancierLenderPresent: any;
  hideDeleteIcon: any;
  isContactPersonPresent: any;
  titles: any = ['Mr', 'Mrs'];
  showAddIcon: any;
  modes: any = ['Email', 'WhatsApp', 'Phone'];
  chooses: any = ['Yes', 'No'];
  financierTypes: any = ['Nationalized Bank', 'NBFC', 'Fintech'];

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private toasterservice: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dailogRef: MatDialogRef<FinancierDailogComponent>
  ) { }

  ngOnInit() {
    this.initForms();
    this.getFinancers();
  }

  initForms() {
    this.financierForm = this.fb.group({
      financierName: [null, Validators.required],
      type: [null, Validators.required],
      mou: [null, Validators.required],
      financierEmail: ['', [Validators.required, Validators.email]],
      contactNo: [null, Validators.required],
      interestRate: [null, Validators.required],
      panNo: [null, Validators.required],
      cin: [null, Validators.required],
      limit: [null, Validators.required],
      longDescription: [null, Validators.required],

    });
    this.contactPersonForm = this.fb.group({
      fullName: [null, Validators.required],
      designation: [null, Validators.required],
      emailId: [null, [Validators.required, Validators.email]],
      phoneNo: [null, Validators.required],
    });
  }

  getFinancers() {
    let lenderId = this.data.lenderID;
    this.dashboardService.getFinancers(lenderId).subscribe(financers => {
      if (financers['status'] === 200) {
        this.setFinancierDetailsIntoForms(financers['result']);
      }
      else {
        if (financers['message']) {
          this.toasterservice.show('Error', financers['message']);
        }
      }
    }, (error) => {
      this.toasterservice.show('Error', 'Financier Details Loading Failed');
      console.log(error);
    }
    );
  }

  setFinancierDetailsIntoForms(financierData) {
    this.setFinancierDetails(financierData);
    this.setFinancierContactDetails(financierData.lenderContact);
  }

  setFinancierDetails(financier) {
    this.financierForm.patchValue(financier);
    this.financierForm.disable();
  }

  setFinancierContactDetails(contacts) {
    this.contactPersonForm.patchValue(contacts);
    this.contactPersonForm.disable();
  }

  closeDailog() {
    this.dailogRef.close()
  }
}
