import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { DashboardService } from '../../dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StopSupplyComponent } from './stop-supply/stop-supply.component';
import { ApproveBuyerComponent } from './approve-buyer/approve-buyer.component';
import { DataService } from '../../../data.service';
import { LoadingService } from '../../../services/loader.service';

@Component({
  selector: 'app-buyers-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule],
  providers: [DashboardService],
  templateUrl: './buyers-list.component.html',
  styleUrl: './buyers-list.component.scss'
})
export class BuyersListComponent implements OnInit {
  displayedColumns: string[] = ['slNo', 'customerName', 'accID', 'sanctionLimit', 'utilizedLimit', 'interestRate', 'status', 'action'];
  dataSource = []
  currentLenderID: any;

  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private loadingService: LoadingService,
  ) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentLenderID = params['lenderId'];
      this.getBuyersList();
    });
  }
  getBuyersList() {

    this.dashboardService.getBuyerList(this.currentLenderID).subscribe(res => {
      if (res['status'] == 200) {
        this.dataSource = res['result'];

      }
      else {

      }
    });
  }

  actionOnBuyer(element) {
    let status = element.status;
    let componentName = status == 'Approved' ? StopSupplyComponent : ApproveBuyerComponent
    if (componentName == StopSupplyComponent) {
      const dialogRef = this.dialog.open(StopSupplyComponent, {
        width: "700px",
        height: "330px",
        data: {
          lenderId: this.currentLenderID,
          buyerId: element.buyerId
        }
      });
      dialogRef.afterClosed().subscribe((result) => {

      });
    }
    else {
      const dialogRef = this.dialog.open(ApproveBuyerComponent, {
        width: "700px",
        height: "180px",
        data: {
          lenderId: this.currentLenderID,
          buyerId: element.buyerId
        }
      });
      dialogRef.afterClosed().subscribe((result) => {

      });
    }
  }
}

