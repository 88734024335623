<div class="custom-toast-container position-fixed top-0 end-0 p-3">
  <ngb-toast *ngFor="let toast of toasts"
    [ngClass]="{'success-toast': toast.type === 'Success', 'error-toast': toast.type === 'Error'}" [autohide]="true"
    [delay]="toast.delay || 5000" (hide)="remove(toast)">
    <div class="toast-header">
      <i class="toast-icon"
        [ngClass]="{'bi-check-circle-fill': toast.type === 'Success', 'bi-exclamation-triangle-fill': toast.type === 'Error'}"></i>
      <strong class="me-auto toast-title">{{ toast.type }}</strong>
      <button type="button" class="btn-close" aria-label="Close" (click)="remove(toast)"></button>
    </div>
    <div class="toast-body">
      {{ toast.content }}
    </div>
  </ngb-toast>
</div>