<!-- <body> -->

<!-- <mat-tab-group>
  <mat-tab label="tab1">

  </mat-tab>
  <mat-tab class="tab2">

  </mat-tab>
  <mat-tab class="tab3">

  </mat-tab>
</mat-tab-group> -->
<div class="account-tabs-sec" #activeKycTabOnKycDone>
  <ul class="nav nav-tabs" role="tablist">
    <li role="presentation" class="nav-item" [ngClass]="{'active': basicDetailShouldDisplay}">
      <a class="nav-link" aria-controls="basicDetails" role="tab" data-toggle="tab">
        <i class="ri-user-line"></i> Basic Details
      </a>
    </li>
    @if(isBuyerUser()){
    <li class="nav-item" [ngClass]="{'active': isCustomerKycDone}">
      <a class="nav-link" (click)="kycComp()" aria-controls="kycVerification" role="tab" data-toggle="tab">
        <i class="ri-user-follow-line"></i> KYC for Financing
      </a>
    </li>
    }
  </ul>
</div>


<div class="cf-lender-details">
  <div class="userDetails">
    <h2 class="user-details-heading">User Detail</h2>
    <div class="user-details-container">
      <div class="user-detail-card">
        <span class="user-detail-title">Email</span>
        <span class="user-detail-info">{{userDetail.email ? userDetail.email : '-'}}</span>
      </div>
      <div class="user-detail-card">
        <span class="user-detail-title">Company Name</span>
        <span class="user-detail-info">{{userDetail.companyName ? userDetail.companyName : '-'}}</span>
      </div>
      <div class="user-detail-card">
        <span class="user-detail-title">GSTIN</span>
        <span class="user-detail-info">{{userDetail.gstIn ? userDetail.gstIn : '-'}}</span>
      </div>
      <div class="user-detail-card">
        <span class="user-detail-title">PAN No</span>
        <span class="user-detail-info">{{userDetail.pan ? userDetail.pan : '-'}}</span>
      </div>
    </div>
  </div>

  @if(isBuyerUser()){
  <div class="card-table">
    <div class="headerSec">
      <h5 class="card-heading">Bank Info</h5>
      <button mat-button color="primary" class="editBankBtn" (click)="addBank()">
        <mat-icon>add_circle</mat-icon>Add Bank
      </button>
    </div>
    <div class="card-content">
      <table mat-table [dataSource]="dataSource" class="custom-mat-table">

        <ng-container matColumnDef="accNumber">
          <th mat-header-cell *matHeaderCellDef> Account Number </th>
          <td mat-cell *matCellDef="let element"> {{element.accNo}} </td>
        </ng-container>

        <ng-container matColumnDef="bankName">
          <th mat-header-cell *matHeaderCellDef> Bank Name </th>
          <td mat-cell *matCellDef="let element"> {{element.bankName}} </td>
        </ng-container>

        <ng-container matColumnDef="branchName">
          <th mat-header-cell *matHeaderCellDef> Branch Name</th>
          <td mat-cell *matCellDef="let element"> {{element.branchName}} </td>
        </ng-container>

        <ng-container matColumnDef="ifscCode">
          <th mat-header-cell *matHeaderCellDef> IFSC Code </th>
          <td mat-cell *matCellDef="let element"> {{element.ifscCode}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  }
</div>