<div class="popup-container">
  <div class="popup">
    <div class="popup-header">
      <h2 class="popup-title">Contact Lender Detail</h2>
      <button class="popup-close" (click)="closepopup()">&times;</button>
    </div>
    <div class="popup-table-container">
      <table class="popup-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="data.length === 0">
            <td colspan="3">No data available</td>
          </tr>
          <tr *ngFor="let lender of [data]">
            <td>{{lender.name}}</td>
            <td>{{lender.contactNumber}}</td>
            <td>{{lender.email}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>