<app-loader></app-loader>

<app-toast-container></app-toast-container>

<div>
  @if(!dataService.isTokenExpired()){
  <nav id="sidebarMenu" class="sidebar bg-white">
    <div class="sidebar-content">
      <div class="list-group">
        <!-- Conditional rendering for Supplier user -->
        @if(isSupplieruser()){
        <a class="list-group-item ripple" [routerLink]="['/supplier/dashboard']" routerLinkActive="active">
          <mat-icon>dashboard</mat-icon>
          <span class="nav-label">Dashboard</span>
        </a>

        <a class="list-group-item ripple" aria-current="true" [routerLink]="['/myaccount']" routerLinkActive="active">
          <mat-icon>account_box</mat-icon><span>My Account</span>
        </a>

        <a class="list-group-item ripple" [routerLink]="['/customer']" routerLinkActive="active">
          <mat-icon> store_mall_directory</mat-icon><span>Customer</span>
        </a>

        <a class="list-group-item ripple" [routerLink]="['/addFinancer']"
          routerLinkActive="active"><mat-icon>person_add</mat-icon><span>Add Financer</span></a>

        <a class="list-group-item ripple" [routerLink]="['supplier/report']" routerLinkActive="active"><mat-icon>
            insert_drive_file</mat-icon><span>View Reports</span></a>
        }

        @else if(isBuyerUser()){
        <a class="list-group-item ripple" [routerLink]="['/myaccount']" routerLinkActive="active">
          <mat-icon>account_box</mat-icon>
          <span class="nav-label">My Account</span>
        </a>
        <a class="list-group-item list-group-item-action py-2 ripple navFlex" [routerLink]="['/buyer/dashboard']"
          routerLinkActive="active"><mat-icon>dashboard</mat-icon><span>Dashboard</span></a>

        <a class="list-group-item list-group-item-action py-2 ripple navFlex" [routerLink]="['buyer/report']"
          routerLinkActive="active"><mat-icon> insert_drive_file</mat-icon><span>View Reports</span></a>
        }
      </div>
    </div>
  </nav>
  }


  @if(!dataService.isTokenExpired()){
  <nav id="main-navbar" class="navbar navbar-expand-lg navbar-light bg-white fixed-top background">
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#sidebarMenu"
        aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="#">
        <img class="my-image" src="../assets/imgs/credlix_logo.svg" height="25" alt="credlix" />
      </a>
      <!-- Search form -->
      <form class="d-none d-md-flex input-group w-auto my-auto">
        <input autocomplete="off" type="search" class="form-control rounded" placeholder='Search (ctrl + "/" to focus)'
          style="min-width: 225px;" />
        <span class="input-group-text border-0"><i class="fas fa-search"></i></span>
      </form>

      <!-- Right links -->
      <ul class="navbar-nav ms-auto d-flex flex-row">
        <!-- Icon -->
        <li class="nav-item">
          <a class="nav-link me-3 me-lg-0" href="#">
            <i class="fas fa-fill-drip"></i>
          </a>
        </li>
        <!-- Icon -->
        <li class="nav-item me-3 me-lg-0">
          <a class="nav-link" href="#">
            <i class="fab fa-github"></i>
          </a>
        </li>

        <!-- Avatar -->
        <li class="nav-item dropdown" (click)="logout()">
          <button mat-button color="warn" class="logoutBtn" (click)="logout()">
            Logout<mat-icon>exit_to_app</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </nav>
  }
  <!-- Navbar -->
  @if(!dataService.isTokenExpired()){
  <div class="routerClass">
    <router-outlet></router-outlet>
  </div>
  }@else{
  <div class="logged">
    <router-outlet></router-outlet>
  </div>
  }

</div>