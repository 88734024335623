import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-stop-supply',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule],
  templateUrl: './stop-supply.component.html',
  styleUrl: './stop-supply.component.scss'
})
export class StopSupplyComponent {
  clearStack($event: MouseEvent) {
  }
  onFileChanged($event: Event) {

  }
  gstReport: any;

}
