import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { urls } from './urls/urls';
import { Observable, catchError, map } from 'rxjs';
import { throwError } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  dataSessionTimeOut!: boolean;
  baseUrl = environment.BASEURL;
  static baseUrl: any = environment.BASEURL;
  validToken = false

  constructor(public httpClient: HttpClient) { }

  callRestful(
    type: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    options?: { params?: {}; body?: {} }
  ) {
    const httpHeaders = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('zone', this.getTimeZone().toString())
        .set('timezoneId', this.getTimeZoneArea())
        .set('Authorization', this.getToken()),
    };

    switch (type) {
      case 'GET':
        return this.httpClient.get(url, httpHeaders).pipe(
          map((res) => res),
          catchError((error) => this.handleError(error, type, url))
        );
      case 'POST':
        console.log('POST payload:', options?.body);
        return this.httpClient.post(url, options?.body, httpHeaders).pipe(
          map((res) => res),
          catchError((error) => this.handleError(error, type, url))
        );
      case 'PUT':
        return this.httpClient.put(url, options?.body, httpHeaders).pipe(
          map((res) => res),
          catchError((error) => this.handleError(error, type, url))
        );
      case 'DELETE':
        return this.httpClient.delete(url, httpHeaders).pipe(
          map((res) => res),
          catchError((error) => this.handleError(error, type, url))
        );
      default:
        return null;
    }
  }

  callRestfulWithFormData(method, url: string, formDataArray: Array<any>): any {
    const fd = new FormData();
    formDataArray.forEach(element => {
      fd.append('file', element);
    });

    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('zone', this.getTimeZone());
    xhr.setRequestHeader('timezoneId', this.getTimeZoneArea());
    xhr.setRequestHeader('Authorization', this.getToken());
    xhr.send(fd);
    return xhr;
  }

  private handleError(
    error: Response | any,
    type?: string,
    url?: string,
    isNotError?: boolean
  ) {
    console.log('error got from api', error);
    if (!isNotError && !error.ok) {
      if (parseInt(error.status) === 302) {
        if (navigator.onLine) {
          this.dataSessionTimeOut = true;
          // this.toasterService.error(
          //   error + 'Session timed out. Please refresh the page.'
          // );
        }
      }
      if (parseInt(error.status) === 500) {
        // this.toasterService.error(
        //   error + 'Something wrong with the application'
        // );
      }
      if (parseInt(error.status) === 0) {
        if (navigator.onLine) {
          this.dataSessionTimeOut = true;
        }
      }
    }
    let errMsg: string;
    // TODO error message on screen
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  getTimeZone(): any {
    return new Date().getTimezoneOffset();
  }
  getTimeZoneArea(): any {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  getToken(): string {
    if (this.getCookie('TOKEN')) {
      let token = this.getCookie('TOKEN');
      token = 'Bearer ' + token;
      return token ? token : undefined;
    } else {
      return undefined;
    }
  }
  public getCookie(name: string) {
    return localStorage.getItem(name);
  }

  public deleteCookie(name: any) {
    localStorage.removeItem(name);
  }

  public setCookie(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  public getSessionValue(name: string) {
    return localStorage.getItem(name);
  }

  public deleteSessionValue(name: any) {
    localStorage.removeItem(name);
  }

  areDatesEqualIgnoringTime(date1: Date, date2: Date): boolean {
    const formatDate = (date: Date): string => {
      return date.toISOString().split('T')[0]; // Extract YYYY-MM-DD part
    };

    const formattedDate1: string = formatDate(date1);
    const formattedDate2: string = formatDate(date2);

    return formattedDate1 === formattedDate2;
  }

  decodetoken(token: any): any {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return null;
    }
  }

  isTokenExpired(): boolean {
    const token: string = this.getToken();
    if (token) {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken && decodedToken.exp) {
        // Get the current timestamp in seconds
        const currentTimestamp = Math.floor(Date.now() / 1000);
        return decodedToken.exp < currentTimestamp;
      }
      return false;
    } else {
      return true;
    }
  }

  isKycVerified() {
    let token = this.getToken();
    let decodedToken = this.decodetoken(token);
    if (decodedToken && decodedToken.isKycDone) {
      return decodedToken.isKycDone;
    }
    else {
      return false;
    }
  }


  public addFinancer(obj: any) {
    const url = environment.BASEURL + urls.ADD_FINANCIER;
    return this.httpClient.post(url, obj);
  }

  public addKyc(obj: any) {
    const url = environment.BASEURL + urls.ADD_FINANCIER;
    return this.httpClient.post(url, obj);
  }

  public saveKycDetails(obj: any) {
    const url = environment.BASEURL + urls.SAVE_KYC_DETAILS;
    return this.httpClient.post(url, obj);
  }
  addBankDetails(obj): any {
    const url = environment.BASEURL + urls.ADD_BANKS_DETAILS;
    return this.httpClient.post(url, obj,);
  }

  getKycData() {
    const url = environment.BASEURL + urls.GET_KYC_DATA;
    return this.httpClient.get(url,);
  }

  getKycDataWithId(id?: string): Observable<any> {
    const url = environment.BASEURL + urls.GET_KYC_DATA;
    let params = new HttpParams();
    params = params.append('id', id);
    return this.httpClient.get(url, { params: params });
  }
}
