import { Injectable, Inject, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { urls } from '../urls/urls';
import { environment } from '../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly baseUrl = environment.BASEURL;
  static baseUrl: any = environment.BASEURL;
  tokenId = signal<string>('');
  validToken = false;
  constructor(
    private http: HttpClient,
    private dataService: DataService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  signIn(obj: any): any {
    let url = this.baseUrl + urls.LOGIN;
    return this.http.post(url, obj);
  }

  signUp(obj: any) {
    const url = this.baseUrl + urls.SIGNUP;
    return this.http.post(url, obj);
  }

  createSession(token: any, USER_INFO: any): any {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('TOKEN', token);
      this.validToken = true;
      // token.set(token.toString());
    }
  }

  public deleteSessionValue(name: any) {
    localStorage.removeItem(name);
  }

  getSessionData(): any {
    if (isPlatformBrowser(this.platformId)) {
      const token = localStorage.getItem('TOKEN');
      return token ? JSON.parse(token) : null;
    }
    return null;
  }
  decodetoken(token: any): any {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error('Error decoding JWT token:', error);
      return null;
    }
  }

  isTokenExpired(): any {
    const token: string = this.dataService.getToken() ? this.dataService.getToken().toString() : null;
    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        // Check if the token has an expiration date
        if (decodedToken && decodedToken.exp) {
          // Get the current timestamp in seconds
          const currentTimestamp = Math.floor(Date.now() / 1000);
          // Compare the expiration timestamp with the current timestamp
          return decodedToken.exp < currentTimestamp;
        }
      }
      catch (error) {
        console.error('Error decoding or validating JWT token:', error);
        return true;
      }
    }
    else {
      return true;
    }
  }
  isbuyerUser() {
    return this.getUserType() == 'BUYER';
  }

  isSupplierUser() {
    return this.getUserType() == 'SUPPLIER';
  }

  getUserType() {
    const decode = jwtDecode(this.dataService.getToken());
    if (decode && decode['role'])
      return decode['role'];
  }
}
