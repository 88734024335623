<div class="cf-container">
  <section class="cf-lenders-main">
    <div class="cf-lender-details">
      <h2 class="user-details-heading">User Detail</h2>
      <div class="user-details-container">
        <div class="user-detail-card">
          <span class="user-detail-title">Company ID</span>
          <span class="user-detail-info">{{userDetail?.companyId ? userDetail?.companyId : '-'}}</span>
        </div>
        <div class="user-detail-card">
          <span class="user-detail-title">Company Name</span>
          <span class="user-detail-info">{{userDetail?.companyName ? userDetail.companyName : '-'}}</span>
        </div>
        <div class="user-detail-card">
          <span class="user-detail-title">Region</span>
          <span class="user-detail-info">{{userDetail?.country ? userDetail.country : '-'}}</span>
        </div>
        <div class="user-detail-card">
          <span class="user-detail-title">Branch Name</span>
          <span class="user-detail-info">{{userDetail?.branchName ? userDetail.branchName : '-'}}</span>
        </div>
        <div class="user-detail-card">
          <span class="user-detail-title">PAN No</span>
          <span class="user-detail-info">{{userDetail?.pan ? userDetail.pan : '-'}}</span>
        </div>
        <!-- Additional details -->
        <div class="user-detail-card">
          <span class="user-detail-title">Email ID</span>
          <span class="user-detail-info">{{userDetail?.email ? userDetail.email : '-'}}</span>
        </div>
        <div class="user-detail-card">
          <span class="user-detail-title">Phone No</span>
          <span class="user-detail-info">{{userDetail?.phoneNo ? userDetail.phoneNo : '-'}}</span>
        </div>
        <div class="user-detail-card">
          <span class="user-detail-title">GSTIN</span>
          <span class="user-detail-info">{{userDetail?.gstIn ? userDetail.gstIn : '-'}}</span>
        </div>
        <div class="user-detail-card kyc-status">
          <span class="user-detail-title">KYC Verification</span>
          <span class="user-detail-info" [ngClass]="isKycVerified ? 'verified' : 'not-verified'">
            <i [ngClass]="isCustomerKycVerified?'ri-checkbox-circle-fill':'ri-close-circle-fill'"></i>

          </span>
        </div>
      </div>
    </div>



    <div class="cf-lender-list">
      <div class="table-responsive">
        <h1 class="lender-list-title"> Lender List </h1>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-mat-table">

          <!-- Define the columns -->
          <ng-container matColumnDef="slNo">
            <th mat-header-cell *matHeaderCellDef> SL No. </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
          </ng-container>

          <ng-container matColumnDef="bankId">
            <th mat-header-cell *matHeaderCellDef> Financing Partner </th>
            <td mat-cell *matCellDef="let element">{{element.bankName}} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef> Interest Rate </th>
            <td mat-cell *matCellDef="let element">
              <span class="interestRate">
                {{element.interestRate}}
                % <mat-icon class="" (click)="interestModel(element.lenderId , element.supplierId)">info</mat-icon>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="spoc">
            <th mat-header-cell *matHeaderCellDef> SPOC </th>
            <td mat-cell *matCellDef="let element">
              <a (click)="viewLenderContact(element.spoc)" [ngClass]="(element.status == loanStatuses.NOT_YET_APPLIED ||
              element.status == loanStatuses.PENDING_SANCTION) ? 'disabledView' : 'enableView'">
                VIEW
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="currentStatus">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
              <!-- [ngClass]="{
              'status': element.currentStatus == loanStatuses.NOT_YET_APPLIED,
              'status status-red': element.currentStatus == loanStatuses.PENDING_SANCTION,
              'status status-green': element.currentStatus == loanStatuses.APPROVED_SANCTION,
              'status status-red': element.currentStatus == loanStatuses.PENDING_DISBURSEMENT,
              'status status-green': element.currentStatus == loanStatuses.APPROVED_DISBURSEMENT
              }" -->
              {{element.status ? element.status : 'Not yet Applied'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              @if((element.status) == loanStatuses.NOT_YET_APPLIED || !element.status){
              <ng-container>
                <button mat-button data-toggle="modal" data-target="#RequestConfirmationModal" class="actionBtn"
                  (click)="openbuyerLoanDialog(element)" [ngClass]="element.action ? 'hide' : ''">
                  {{loanStatuses.APPLY}}
                </button>
              </ng-container>
              }
              @else if(element.status != loanStatuses.NOT_YET_APPLIED){
              <ng-container>
                <!-- (click)="changeToPendingDisbursement(element?.financeBazaarLoanId, loanStatuses.APPROVED_DISBURSEMENT, element?.disbursementUrl)" -->
                <button mat-button [disabled]="element.status == 'PENDING'" class="actionBtn"
                  (click)="openInitiatePaymentDialog(element)">
                  {{
                  element.status == loanStatuses.PENDING_SANCTION ? loanStatuses.APPLY :
                  element.status == loanStatuses.APPROVED_SANCTION ?
                  loanStatuses.INITIATE_DISBURSEMENT :
                  element.status == loanStatuses.APPROVED_DISBURSEMENT ?
                  loanStatuses.INITIATE_DISBURSEMENT :
                  element.status == 'PENDING' ? 'Apply' : element.status == 'APPROVED' ? 'Initiate Disbursement' :
                  element.status
                  }}
                </button>
              </ng-container>
              }
            </td>
          </ng-container>

          <!-- Define the table rows using the defined columns -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </div>
  </section>