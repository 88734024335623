import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  isLoaded: boolean = false;

  private loaderState: Subject<boolean> = new Subject<boolean>();

  setLoadingState(state: boolean): void {
    this.loaderState.next(state);
    console.log('setLOADING1', state);
  }

  getLoadingState(): Observable<boolean> {
    return this.loaderState.asObservable();
  }
}
