<div>
  <div class="header">
    <h3>
      Sanction Approval Form
    </h3>
  </div>
  <div class="inpfields">
    <mat-form-field appearance="outline">
      <mat-label>Approve Limit</mat-label>
      <input type="number" matInput [(ngModel)]="approvelimit" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="intrestrate">
      <mat-label>Interest Rate</mat-label>
      <input matInput type="number" [(ngModel)]="interestRate" />
    </mat-form-field>

  </div>
  <div class="actionBtn">
    <button mat-button color="primary" class="approveBtn" (click)="approveSanction()"> Approve Sanction</button>
  </div>
</div>