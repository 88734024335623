<div class="form-container">
  <div class="form-header">
    <span>Lenders List</span>
    <mat-icon (click)="closePopup()">close</mat-icon>
  </div>
  <div class="misreportTbl custom-table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="slNo">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef> Financier Name </th>
        <td mat-cell *matCellDef="let element"> {{element.financier ? element.financier : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="bankName">
        <th mat-header-cell *matHeaderCellDef> Sanction Limit </th>
        <td mat-cell *matCellDef="let element"> {{element.requestedLimit ? element.requestedLimit : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="sanctionLimit">
        <th mat-header-cell *matHeaderCellDef> Interest Rate</th>
        <td mat-cell *matCellDef="let element"> {{element.approvedRate ? element.approvedRate : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="LimitUtilized">
        <th mat-header-cell *matHeaderCellDef> Limit Utilized </th>
        <td mat-cell *matCellDef="let element"> {{element.utilizedLimit ? element.utilizedLimit : '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="limitExpirydate">
        <th mat-header-cell *matHeaderCellDef> Limit Expiry Date</th>
        <td mat-cell *matCellDef="let element"> {{addDaysAndFormat(element.createdOn, 364)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


  </div>