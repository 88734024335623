import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AddFinancierService } from './add-finacer.service';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-add-financier',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [AddFinancierService],
  templateUrl: './add-financier.component.html',
  styleUrl: './add-financier.component.scss',
})
export class AddFinancierComponent implements OnInit {
  financierForm!: FormGroup;
  contactPersonForm: FormGroup;
  isAddFinancierRequest: any;
  isFinancierLenderPresent: any;
  hideDeleteIcon: any;
  isContactPersonPresent: any;
  titles: any = ['Mr', 'Mrs'];
  showAddIcon: any;
  modes: any = ['Email', 'WhatsApp', 'Phone'];
  chooses: any = ['Yes', 'No'];
  financierTypes: any = ['Nationalized Bank', 'NBFC', 'Fintech'];

  constructor(
    private fb: FormBuilder,
    private financerService: AddFinancierService,
    private toasterservice: ToastService
  ) { }

  ngOnInit() {
    this.financierForm = this.fb.group({
      financierName: [null, Validators.required],
      type: [null, Validators.required],
      mou: [null, Validators.required],
      financierEmail: ['', [Validators.required, Validators.email]],
      contactNo: [null, Validators.required],
      interestRate: [null, Validators.required],
      panNo: [null, Validators.required],
      cin: [null, Validators.required],
      limit: [null, Validators.required],
      longDescription: [null],

    });
    this.contactPersonForm = this.fb.group({
      fullName: [null, Validators.required],
      // type: ['', Validators.required],
      designation: [null, Validators.required],
      emailId: [null, [Validators.required, Validators.email]],
      phoneNo: [null, Validators.required],
    });
  }

  get contactPersonListFormArray() {
    return this.financierForm.get('contactPersonList') as FormArray;
  }
  submitFinancierForm() {
    let obj: FinacierModel = this.financierForm.value;
    obj.lenderContact = this.contactPersonForm.value
    if (this.financierForm.valid && this.contactPersonForm.valid)
      this.financerService.addfinancer(obj).subscribe((res) => {
        if (res && res['success']) {
          this.financierForm.reset();
          this.contactPersonForm.reset();
        }
        else {
          this.toasterservice.show('Error', res['message']);
        }
      });
  }
  getContactPersons(): any {
    return [];
  }

  addContactPerson() {
    const contactPersonFormGroup = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // other contact person form controls...
    });
    this.contactPersonListFormArray.push(contactPersonFormGroup);
  }

  removeContactPerson(index: number) {
    this.contactPersonListFormArray.removeAt(index);
  }

  onSubmit() { }
}
export interface FinacierModel {
  bankId: string;
  lenderName: string;
  financierType: string;
  mou: number;
  lenderEmailId: string;
  lenderMobile: number;
  interestRate: number;
  panNumber: number;
  cin: string;
  mouLimit: number;
  listedThroughAggregator: string;
  description: string;
  modeOfCommunication: string;
  lenderContact: object;
}
