<div class="signup_wrapper">
  <div class="wrap">
    <div class="right_panel">
      <div class="background_cover"></div>
    </div>
    <div class="left_panel">
      <div class="credlix_logo">
        <img src="../../assets/imgs/loginlogo.svg" />
      </div>
      <h5>Login</h5>
      <form [formGroup]="LoginForm" (ngSubmit)="onSubmit()">
        <div class="signup_form">
          <mat-form-field appearance="outline">
            <mat-label>Email ID</mat-label>
            <input matInput formControlName="email" [autocomplete]="true" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password" [autocomplete]="true" />
          </mat-form-field>
          <!-- <a (click)="resetPassword()" fxLayoutAlign="end">Forgot Password?</a> -->
          <div fxLayout="row" flexLayoutAlign="flex-end" align-content="center">
            <span class="txtWrp" style="font-size: 14px">
              <a href="/signup" fxLayout>Signup for Dealer Financing</a>
            </span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10%">
          <div fxLayout="row" class="dwn-btn">
            <button mat-raised-button class="btn_login mcrsft">LOGIN</button>
          </div>
        </div>
      </form>
      <div class="mdl">Or</div>
      <div fxLayout="row" fxLayoutGap="80%">
        <div fxLayout="row" class="dwn-btn">
          <button mat-raised-button class="btn_login mcrsft" (click)="loginWithMicrosoft()">
            Login with Microsoft Account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="window-size" >
    <div class="logo">
      <img src="/assets/images/Lgo.svg">
    </div>
    <h5>Credlix is coming soon for your device. <br>Please visit using a Laptop/Desktop for now</h5>
  </div> -->