import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MyAccountService } from '../../myAccount.service';
import { DataService } from '../../../data.service';
import { HttpClientModule } from '@angular/common/http';
import { LoadingService } from '../../../services/loader.service';

@Component({
  selector: 'app-add-bank',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [MyAccountService, DataService, LoadingService],
  templateUrl: './add-bank.component.html',
  styleUrl: './add-bank.component.scss'
})
export class AddBankComponent implements OnInit {
  addBanksDetails: BanksData[] = [];
  bankDetails: any[] = [];
  constructor(
    private dialogRef: DialogRef<AddBankComponent>,
    private myAccountService: MyAccountService,
    private loadingService: LoadingService
  ) {

  }
  ngOnInit() {
    this.addnewRow();
  }

  addnewRow() {
    this.bankDetails.push({
      accNo: null,
      bankName: null,
      branchName: null,
      ifscCode: null
    })
  }
  closePopup(reload?: boolean) {
    if (reload) {
      this.dialogRef.close();
    }
    else {
      this.dialogRef.close();
    }
  }

  deleteRow(index: number): void {
    this.bankDetails.splice(index, 1);
  }

  addBanksDetail() {

    if (this.bankDetails && this.bankDetails.length > 0) {
      this.myAccountService.addBankDetails(this.bankDetails).subscribe(res => {
        if (res.status === 200) {
          this.closePopup(true);
          this.loadingService.setLoadingState(false);
        }
        else {
          this.loadingService.setLoadingState(false);
        }
      });
    }
  }
}

export interface BanksData {
  accNo: Number;
  bankName: Number;
  branchName: Number;
  ifscCode: Number;
}
