<ng-container class="buyerLoanContainer">
  <div class="popupContent">
    <div class="loanheader">
      <h2 class="loanHeader">Financing Request</h2>
      <mat-icon (click)="closePopup()">close</mat-icon>
    </div>
    <div class="warnMsg">
      <p>Before applying for loan kindly provide few details to help us provide you the better credit limit.</p>
    </div>

    <div class="limitRequirment">
      <mat-form-field appearance="outline">
        <mat-label>Limit Requirement(in Cr)</mat-label>
        <input matInput type="number" [(ngModel)]="limitRequired"/>
      </mat-form-field>
      <div class="btnDiv">
        <button mat-button class="cancelBtn" color="primary" (click)="closePopup()"> cancel</button>
        <button mat-button class="applyBtn" color="warn" [disabled]="checkDocs" (click)="applyFinancingRequest()"> Apply</button>
      </div>
    </div>

    <div class="reqDocs">
      <p class="secName">Required Document</p>
      <div>
        <section>
          <div class="v-kyc-row clearfix">
            <div class="f-field">
              <span class="inputspan">
                @if(!gstReportName){
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">GST Report</label>
                <input type="file" class="form-control" #gstInput id="gstInput" [(ngModel)]="gstReport"
                  placeholder="GST Report" [multiple]="false" (click)="clearStack($event)"
                  (change)="onFileChanged($event, 'GST Report')">
                @if(!gstReport){
                <mat-icon (click)="triggerFileInput(gstInput)" class="uploadIcon">cloud_upload</mat-icon>
                }@else{
                <mat-icon class="uploadIcon">close</mat-icon>
                }
                }@else{
                <span>
                  <mat-label>GST Report</mat-label>
                  <input [(ngModel)]="gstReportName" readonly="true" (click)="downloadFile('gstReport')" />
                  <mat-icon>cloud_download</mat-icon>
                </span>
                }
              </span>
            </div>

            <div class="f-field">
              @if(!gstregistrationName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">GST Registration</label>
              <input type="file" class="form-control" [(ngModel)]="gstregistration" placeholder="GST Registration"
                [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'GST Registration')">
              @if(!gstregistration){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }
              @else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }@else{
              <span>
                <mat-form-field appearance="outline">
                  <mat-label>GST Registration</mat-label>
                  <input matInput [(ngModel)]="gstregistrationName" readonly="true"
                    (click)="downloadFile('gstregistration')" />
                  <mat-icon>cloud_download</mat-icon>
                </mat-form-field>
              </span>
              }
            </div>

            <div class="f-field">
              @if(!partenershipDeadName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">MOA /AOA/ Partenership</label>
              <input type="file" id="dateOfAssociation" class="form-control" placeholder="MOA /AOA/ Partenership"
                [(ngModel)]="partenershipDead" [multiple]="false" (click)="clearStack($event)"
                (change)="onFileChanged($event, 'moa')">
              @if(!partenershipDead){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }
              @else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <div class="attachedFile">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">MOA /AOA/ Partenership</label>
                <input [(ngModel)]="partenershipDeadName" readonly="true" (click)="downloadFile('partenershipDead')" />
                <mat-icon>cloud_download</mat-icon>
              </div>
              }
            </div>
          </div>

          <div class="v-kyc-row clearfix">

            <div class="f-field">
              @if(!panName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">PAN</label>
              <input type="file" id="dateOfBirth" class="form-control" placeholder="PAN" [(ngModel)]="pan"
                [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'pan')">
              @if(!pan){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }@else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>PAN</mat-label>
                <input matInput [(ngModel)]="panName" readonly="true" (click)="downloadFile('pan')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field>
              }
            </div>
            <div class="f-field">
              @if(!iecName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">IEC Certificate</label>
              <input type="file" class="form-control" placeholder="IEC Certificate" [(ngModel)]="iec" [multiple]="false"
                (click)="clearStack($event)" (change)="onFileChanged($event, 'IEC')">
              @if(!iec){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              } @else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>IEC Certificate</mat-label>
                <input matInput [(ngModel)]="iecName" readonly="true" (click)="downloadFile('pan')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field>
              }
            </div>

            <div class="f-field">
              @if(!bankStatementName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Last 3 Month Bank Statement</label>
              <input type="file" class="form-control" placeholder="Last 3 Month Banck statement"
                [(ngModel)]="bankStatement" [multiple]="false" (click)="clearStack($event)"
                (change)="onFileChanged($event, 'bankStatement')">
              @if(!bankStatement){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }@else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>Last 3 Month Bank Statement</mat-label>
                <input matInput [(ngModel)]="bankStatementName" readonly="true"
                  (click)="downloadFile('bankStatement')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field>
              }
            </div>
          </div>

          <div class="v-kyc-row clearfix">
            <div class="f-field">

              @if(!termLoanName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Detail of Term Loan</label>
              <input type="file" class="form-control" placeholder="Detail of Term Loan" [(ngModel)]="termLoan"
                [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'loanterm')">
              @if(!termLoan){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }@else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>Detail of Term Loan</mat-label>
                <input matInput [(ngModel)]="termLoanName" readonly="true" (click)="downloadFile('termLoan')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field>
              }
            </div>

            <div class="f-field">
              @if(!balancesheetName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Last 3 year Audited Balance Sheet</label>
              <input type="file" class="form-control" placeholder="Last 3 year Audited Balance Sheet"
                [(ngModel)]="balancesheet" placeholder="Last 3 year Audited Balance Sheet" [multiple]="false"
                (click)="clearStack($event)" (change)="onFileChanged($event, 'balanceSheet')">
              @if(!balancesheet){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }@else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>Last 3 year Audited Balance Sheet</mat-label>
                <input matInput [(ngModel)]="balancesheetName" readonly="true" (click)="downloadFile('balancesheet')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field>
              }
            </div>
            <div class="f-field itrclass">
              @if(!itrReportName){
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Upload ITR Report for Borrowers</label>
              <input type="file" class="form-control" placeholder="Upload ITR Report for Borrowers"
                [(ngModel)]="itrReport" placeholder="Upload ITR Report for Borrowers" [multiple]="false"
                (click)="clearStack($event)" (change)="onFileChanged($event, 'itr')">
              @if(!itrReport){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }@else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>Upload ITR Report for Borrowers</mat-label>
                <input matInput [(ngModel)]="itrReportName" readonly="true" (click)="downloadFile('itrReport')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field>
              }
            </div>

          </div>
        </section>
      </div>
    </div>
    <div class="warnDiv">
      <mat-checkbox class="example-margin" [(ngModel)]="agreeTerms">
        <p class="termsCondition">
          I agree to share my documents with the lender as part of the financing request
        </p>
      </mat-checkbox>
    </div>
  </div>
</ng-container>