import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';
import { urls } from '../../app/urls/urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) { }
  addCustomer(obj) {
    const url = environment.BASEURL + urls.ADD_CUSTOMER;
    return this.httpClient.post(url, obj,);
  }

  getBuyerCustomersDetails() {
    const url = environment.BASEURL + urls.GET_BUYER_DETAILS;
    return this.httpClient.get(url,);
  }

  getSupplierCustomersDetails() {
    const url = environment.BASEURL + urls.GET_CUSTOMER_DETAILS;
    return this.httpClient.get(url,);
  }
}
