<div class="popupDiv">

  <div class="headerDiv">
    <h3>Stop Supply</h3>
  </div>
  <p>Are you sure you want to stop all the transactions with the customer?
    Please mention the reason for stopping the supply.</p>
  <div class="uploadDiv">
    <input type="file" class="fileInp" [(ngModel)]="gstReport" placeholder="GST Report" [multiple]="false"
      (click)="clearStack($event)" (change)="onFileChanged($event)">
  </div>
  <div class="remarkDiv">
    <mat-form-field appearance="outline">
      <mat-label>Enter Remark</mat-label>
      <input matInput />
    </mat-form-field>
  </div>
  <div class="actionBtn">
    <button class="clearAllBtn" color="warn" mat-button>Clear All</button>
    <button class="cnfBtn" color="primary" mat-button>Confirm & Send</button>
  </div>

</div>