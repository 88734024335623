import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardService } from '../../../dashboard.service';
import { ToastService } from './../../../../services/toast.service';
import { DataService } from '../../../../data.service';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from '../../../../services/loader.service';

@Component({
  selector: 'app-approve-buyer',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [DashboardService, DataService, HttpClient],
  templateUrl: './approve-buyer.component.html',
  styleUrl: './approve-buyer.component.scss'
})
export class ApproveBuyerComponent {
  approvelimit: any;
  interestRate: any;

  constructor(
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ApproveBuyerComponent>,
    private toaster: ToastService,
    private loadingService: LoadingService,
  ) {

  }

  approveSanction() {

    const obj = {
      'buyerId': this.data.buyerId,
      'lenderId': this.data.lenderId,
      'approvedLimit': this.approvelimit,
      'interestRate': this.interestRate
    };
    this.dashboardService.approveSanction(obj).subscribe(responce => {
      if (responce['success']) {
        this.toaster.show('Success', 'Approved');
        this.loadingService.setLoadingState(false);
        this.dialogRef.close();
      }
      else {
        this.toaster.show('Error', responce['message']);
        this.loadingService.setLoadingState(false);

      }
    }, err => {
      this.toaster.show('Error', 'Something Went Wrong');
      this.loadingService.setLoadingState(false);

    });
  }
}
