import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';
import { urls } from '../urls/urls';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private dataService: DataService, private httpClient: HttpClient) {
  }

  getMisReportsData(buyerId?: string) {
    let url = null;
    if (buyerId) {
      url = environment.BASEURL + urls.GET_REPORT_LIST + '?id=' + buyerId;;
    }
    else {
      url = environment.BASEURL + urls.GET_REPORT_LIST;
    }
    return this.httpClient.get(url);
  }

  downloadReport(obj) {
    const url = environment.BASEURL + urls.DOWNLOAD_REPORT;
    return this.httpClient.post(url, obj, { responseType: 'blob' });
  }
}
