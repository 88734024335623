import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { ReportService } from '../../reports/report.service';
import { ToastService } from '../../services/toast.service';
import { LoadingService } from '../../services/loader.service';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lender-list-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule
  ],
  providers: [DatePipe],
  templateUrl: './lender-list-dialog.component.html',
  styleUrl: './lender-list-dialog.component.scss'
})
export class LenderListDialogComponent implements OnInit {
  displayedColumns: string[] = ['slNo', 'customerName', 'bankName', 'sanctionLimit', 'LimitUtilized', 'limitExpirydate'];
  dataSource: any[];

  constructor(
    private datePipe: DatePipe,
    private reportService: ReportService,
    private toasterservice: ToastService,
    private loaderService: LoadingService,
    private dialogRef: MatDialogRef<LenderListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }
  ngOnInit() {
    this.getMisReports(this.data.buyerId);
  }

  getMisReports(buyerId: string) {
    const Id = ''
    this.reportService.getMisReportsData(buyerId).subscribe((responce) => {
      if (responce['status'] === 200) {
        this.dataSource = responce['result'];
      }
      else {
        this.toasterservice.show('Error', 'Mis Report loading getting failed');
      }
      ;
    });
  }

  addDaysAndFormat(dateString: string, daysToAdd: number): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return this.datePipe.transform(date, 'fullDate');
  }

  closePopup() {
    this.dialogRef.close();
  }
}
