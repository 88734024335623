@if (showLoader) {
<div class="loader-overlay">
  <div class="loader-circle loading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
}