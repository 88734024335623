<div class="header">
  <div class="flex-row-header">
    <h3>Bank MIS Report</h3>
  </div>
  <div class="content">
    <div class="f-field">
      <label>Select Report</label>
      <div class="downloadFlex">
        <select class="form-control" [(ngModel)]="selectedReport">
          @for(report of documentsList; track report){
          <option [value]="report">{{report}}</option>
          }
        </select>

        <button class="downoadBtn" mat-button [matMenuTriggerFor]="menu">
          <mat-icon>cloud_download</mat-icon>
          Download </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="downloadReport('xlsx')">
            <mat-icon>table_view</mat-icon>
            <span>Excel file</span>
          </button>
          <button mat-menu-item (click)="downloadReport('csv')">
            <mat-icon>text_snippet</mat-icon>
            <span>CSV file</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>