import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { DataService } from '../data.service';
import { LoadingService } from './../services/loader.service'; // Make sure to import the LoadingService
import { ToastService } from '../services/toast.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService, private loadingService: LoadingService, private toasterService: ToastService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.dataService.getToken();
    // Start the loader
    this.loadingService.setLoadingState(true);;

    let authReq = request;
    if (token) {
      authReq = request.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
    }
    return next.handle(authReq).pipe(
      // Stop the loader whether the request was successful or not
      finalize(() => {
        this.loadingService.setLoadingState(false);;

      }
      )
      ,
      catchError((error: HttpErrorResponse) => {
        if (!window.navigator.onLine) {
          this.toasterService.show('Error', "Please check your Internet Connection")
          return throwError(() => error);
        }
        if (error['status'] == 410 && error.error != null) {
          this.dataService.deleteSessionValue('TOKEN');
          this.router.navigate(['/login'])
            .then(() => {
              window.location.reload();
            });
        }
        else if (error['status'] == 410) {
          this.dataService.deleteSessionValue('TOKEN');
          this.router.navigate(['/login'])
            .then(() => {
              window.location.reload();
            });
        }

        this.toasterService.show('Error', error.error.message || error.message);
        return throwError(() => error);;
      })
    );
  }
}
